import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.GRANT_SERVER_PREFIX}/report`
const endpoints = {
    getExampleReport: () => `${RESOURCE_ENDPOINT}/example-report`,
    getCircularInformationReport: () => `${RESOURCE_ENDPOINT}/circular-information-report`,
    getNSTFellowshipApplicantReport: () => `${RESOURCE_ENDPOINT}/nst-fellowship-applicant-report`,
    getNSTFellowshipPreliminarySelectedApplicantReport: () => `${RESOURCE_ENDPOINT}/nst-fellowship-preliminary-selected-applicant-report`,
    getNSTFellowshipMarkDistributionReport: () => `${RESOURCE_ENDPOINT}/nst-fellowship-mark-distribution-report`,
    getSpecialResearchGrantApplicantReport: () => `${RESOURCE_ENDPOINT}/special-research-grant-applicant-report`,
    getSpecialResearchGrantApplicantPublicationReport: () => `${RESOURCE_ENDPOINT}/special-research-grant-applicant-publication-report`,
    getSpecialResearchGrantBillForIBASReport: () => `${RESOURCE_ENDPOINT}/special-research-grant-bill-ibas`,
    getNSTFellowshipBillForIBASReport: () => `${RESOURCE_ENDPOINT}/nst-fellowship-bill-ibas`,
    getNSTFellowshipBillReport: () => `${RESOURCE_ENDPOINT}/nst-fellowship-bills`,
    getSpecialResearchGrantApprovedProjectsReport: () => `${RESOURCE_ENDPOINT}/special-research-grant-approved-projects`,
    getResearcherPublicationReport: () => `${RESOURCE_ENDPOINT}/srg-researcher-publication`,
    getSpecialResearchGrantProjectComparisonReport: () => `${RESOURCE_ENDPOINT}/special-research-grant-project-comparison-report`,
    getSpecialResearchGrantPreliminarySelectedApplicantReport: () => `${RESOURCE_ENDPOINT}/special-research-grant-preliminary-selected-applicant-report`,
    getSpecialResearchGrantMarkDistributionReport: () => `${RESOURCE_ENDPOINT}/special-research-grant-mark-distribution-report`,
    getSpecialResearchGrantApplicantBondReport: () => `${RESOURCE_ENDPOINT}/special-research-grant-applicant-bond-report`,
    getSpecialResearchGrantRecipientReport: () => `${RESOURCE_ENDPOINT}/special-research-grant-recipient-report`,
    getScientificOrgApplicantReport: () => `${RESOURCE_ENDPOINT}/scientific-org-applicant-report`,
    getScientificOrgPreliminarySelectedApplicantReport: () => `${RESOURCE_ENDPOINT}/scientific-org-preliminary-selected-applicant-report`,
    getBSTFApplication: () => `${RESOURCE_ENDPOINT}/bstf-application-report`,
    getBSTFApplicant: () => `${RESOURCE_ENDPOINT}/bstf-applicant-report`,
    getBSTFApplicantDetails: () => `${RESOURCE_ENDPOINT}/bstf-applicant-details-report`,
    getBSTFApplicantGO: () => `${RESOURCE_ENDPOINT}/bstf-applicant-go-report`,
    getBSTFOneYearApplicant: () => `${RESOURCE_ENDPOINT}/bstf-applicant-report-one-year-fellow-list`,
    getRandDProjectMoSTApplication: () => `${RESOURCE_ENDPOINT}/r-and-d-project-most-application-report`,
    getRandDProjectMoSTApplicant: () => `${RESOURCE_ENDPOINT}/r-and-d-project-most-applicant-report`,
    getPeerReviewCommitteeMember: () => `${RESOURCE_ENDPOINT}/peer-review-committee-member-report`,
    getInternshipThesisWaitng: () => `${RESOURCE_ENDPOINT}/internship-thesis-applicant-waiting-report`,
    getBatchWiseInternshipThesis: () => `${RESOURCE_ENDPOINT}/batch-wise-internship-thesis-report`,
    getInternshipThesisReport: () => `${RESOURCE_ENDPOINT}/internship-thesis-report`,
    getInstituteWiseInternshipThesisReport: () => `${RESOURCE_ENDPOINT}/institute-wise-internship-thesis-report`,
    getNonGovtReport: () => `${RESOURCE_ENDPOINT}/non-govt-institute-application-report`,
    getNonGovtPreliminarySelectedReport: () => `${RESOURCE_ENDPOINT}/non-govt-institute-preliminary-selected-report`,
    getNonGovtBillForIBASReport: () => `${RESOURCE_ENDPOINT}/non-govt-institute-bill-ibas`,
    getResearchFellowshipApplication: () => `${RESOURCE_ENDPOINT}/research-fellowship-application-report`,
}

export default class ReportGrantApi {
    public getExampleReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getExampleReport();
        return HttpService.get(url, params, headers);
    }
    public getCircularInformationReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getCircularInformationReport();
        return HttpService.get(url, params, headers);
    }
    public getNSTFellowshipApplicantReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getNSTFellowshipApplicantReport();
        return HttpService.get(url, params, headers);
    }
    public getNSTFellowshipPreliminarySelectedApplicantReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getNSTFellowshipPreliminarySelectedApplicantReport();
        return HttpService.get(url, params, headers);
    }
    public getNSTFellowshipMarkDistributionReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getNSTFellowshipMarkDistributionReport();
        return HttpService.get(url, params, headers);
    }
    public getSpecialResearchGrantApplicantReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getSpecialResearchGrantApplicantReport();
        return HttpService.get(url, params, headers);
    }
    public getSpecialResearchGrantApplicantPublicationReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getSpecialResearchGrantApplicantPublicationReport();
        return HttpService.get(url, params, headers);
    }
    public getSpecialResearchGrantBillForIBASReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getSpecialResearchGrantBillForIBASReport();
        return HttpService.get(url, params, headers);
    }
    public getNSTFellowshipBillForIBASReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getNSTFellowshipBillForIBASReport();
        return HttpService.get(url, params, headers);
    }
    public getNSTFellowshipBillReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getNSTFellowshipBillReport();
        return HttpService.get(url, params, headers);
    }
    public getNonGovtBillForIBASReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getNonGovtBillForIBASReport();
        return HttpService.get(url, params, headers);
    }
    public getSpecialResearchGrantApprovedProjectsReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getSpecialResearchGrantApprovedProjectsReport();
        return HttpService.get(url, params, headers);
    }
    public getResearcherPublicationReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getResearcherPublicationReport();
        return HttpService.get(url, params, headers);
    }
    public getSpecialResearchGrantProjectComparisonReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getSpecialResearchGrantProjectComparisonReport();
        return HttpService.get(url, params, headers);
    }
    public getSpecialResearchGrantPreliminarySelectedApplicantReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getSpecialResearchGrantPreliminarySelectedApplicantReport();
        return HttpService.get(url, params, headers);
    }
    public getSpecialResearchGrantMarkDistributionReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getSpecialResearchGrantMarkDistributionReport();
        return HttpService.get(url, params, headers);
    }
    public getSpecialResearchGrantApplicantBondReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getSpecialResearchGrantApplicantBondReport();
        return HttpService.get(url, params, headers);
    }
    public getSpecialResearchGrantRecipientReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getSpecialResearchGrantRecipientReport();
        return HttpService.get(url, params, headers);
    }
    //
    public getScientificOrgApplicantReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getScientificOrgApplicantReport();
        return HttpService.get(url, params, headers);
    }
    public getScientificOrgPreliminarySelectedApplicantReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getScientificOrgPreliminarySelectedApplicantReport();
        return HttpService.get(url, params, headers);
    }
    //
    public getRandDProjectMoSTApplication = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getRandDProjectMoSTApplication();
        return HttpService.get(url, params, headers);
    }
    public getRandDProjectMoSTApplicant = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getRandDProjectMoSTApplication();
        return HttpService.get(url, params, headers);
    }
    public getPeerReviewCommitteeMember = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getPeerReviewCommitteeMember();
        return HttpService.get(url, params, headers);
    }
    public getBSTFApplication = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getBSTFApplication();
        return HttpService.get(url, params, headers);
    }

    public getBSTFApplicant = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getBSTFApplicant();
        return HttpService.get(url, params, headers);
    }

    public getNonGovtReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getNonGovtReport();
        return HttpService.get(url, params, headers);
    }
    public getNonGovtPreliminarySelectedReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getNonGovtPreliminarySelectedReport();
        return HttpService.get(url, params, headers);
    }

    public getBSTFApplicantDetails = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getBSTFApplicantDetails();
        return HttpService.get(url, params, headers);
    }
    public getBSTFApplicantGO = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getBSTFApplicantGO();
        return HttpService.get(url, params, headers);
    }

    public getBSTFOneYearApplicant = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getBSTFOneYearApplicant();
        return HttpService.get(url, params, headers);
    }

    public getInternshipThesisWaitng = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getInternshipThesisWaitng();
        return HttpService.get(url, params, headers);
    }

    public getBatchWiseInternshipThesis = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getBatchWiseInternshipThesis();
        return HttpService.get(url, params, headers);
    }
    public getInternshipThesisReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getInternshipThesisReport();
        return HttpService.get(url, params, headers);
    }
    public getInstituteWiseInternshipThesisReport = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getInstituteWiseInternshipThesisReport();
        return HttpService.get(url, params, headers);
    }

    public getResearchFellowshipApplication = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.getResearchFellowshipApplication();
        return HttpService.get(url, params, headers);
    }
}
